import { CommonEnvironment } from './environment.common';

export class Environment extends CommonEnvironment {
  static adsPrice: number = 500;
  static firebase: any = {
    apiKey: "AIzaSyBSiEipm_Wa-sxi1JJk8olFMHilUIHG_qg",
    authDomain: "hvaskjer-staging.firebaseapp.com",
    databaseURL: "https://hvaskjer-staging.firebaseio.com",
    projectId: "hvaskjer-staging",
    storageBucket: "hvaskjer-staging.appspot.com",
    messagingSenderId: "840091415678",
    appId: "1:840091415678:web:577a9ac8afb78feb1f942f",
    measurementId: "G-BQMWCQEYXV"
  };
  static facebookAppID: string = '191637625018822';
  static production: any = false;
  static contact: any = {
    email: 'post@midtbyen.no',
    facebookSlug: 'HvaSkjerITrondheim',
    facebookName: 'Hva skjer i Trondheim?',
  };
  static content: any = {
    siteName: 'HVA SKJER STAGING',
    siteNameTranslatable: {
      nb: 'trdevents.no',
      en: 'trdevents.no',
    },
    titleFrontpage: {
      en: 'Events in Trondheim',
      nb: 'Hva skjer i Trondheim?'
    },
    aboutus: {
      en: '<p>Trdevents.no is owned and operated by Trondheim Management AS, which is a private-public company owned by Samarbeidsgruppen Midtby´n, Visit Trondheim AS, Trondheim Gårdeierforening and Trondheim Municipality. Trondheim Management works for a vibrant and attractive city and centre. The company contributes to Trondheim being well organized for culture, business, trade and leisure, and is an important actor and partner for the visibility and marketing of the city\'s diverse offer.</p>',
      nb: '<p>Trdevents.no eies og driftes av Trondheim Management AS, som er et privat-offentlig selskap eid av Samarbeidsgruppen Midtby´n, Visit Trondheim AS, Trondheim Gårdeierforening og Trondheim Kommune. Trondheim Management jobber for en levende og attraktiv by og sentrum. Selskapet bidrar til at Trondheim er godt tilrettelagt for kultur, næring, handel og fritid, og er en viktig aktør og samarbeidspartner for synliggjøring og markedsføring av byens mangfoldige tilbud.</p><div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="8px"><div>Trondheim Management</div><div>Nordre gate 10, 7011 Trondheim</div><div><a class="email" href="mailto:post@midtbyen.no">post@midtbyen.no</a></div><div>+47 993 54 000</div><div>Org. nr 995 860 465</div></div><div class="full-width bottom" fxLayout="column" fxLayoutAlign="center center"><div class="title">Powered by</div><div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="30px"><img style="max-heigth: 50px" src="../../assets/logos/midtbyen-name.png"/><img style="max-heigth: 50px" src="../../assets/logos/visit-trondheim.png"/></div></div>'
    },
    welcomeMessage: {
      nb: 'Hva skjer i Trondheim. Kalender over konserter, festivaler, show, kunsthus, teater, debatter og mye annet! Årets begivenheter finner du her.',
      en: 'What\'s going on in Trondheim. Calendar with concerts, festivals, shows, art houses, theaters, debates and more things! This year\'s events can be found here.'
    }
  };
  static newsletterEnabled: boolean = true;
  static owner: any = {
    name: "Midtbyen Management",
    link: 'https://www.midtbyen.no/'
  };
  static partners: any[] = [{
    logo: 'trdevents-logo.png'
  }];
  static siteURL: string = 'https://hvaskjer-staging.web.app';
  static town: any = {
    name: 'Trondheim',
    hint: 'Trondheim Norway',
    lat: 63.430133,
    lng: 10.392836,
  };

  static ui: any = {
    ... CommonEnvironment.ui,
    showOnForm: {
      ... CommonEnvironment.ui.showOnForm,
      featuredEvent: true,
      featuredEventOnlyAdmins: true
    },
    titleNewsletter: {
      en: 'Newsletter subscription',
      nb: 'Påmelding til nyhetsbrev'
    },
  };
};

export const environment: any = Environment;
